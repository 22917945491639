export enum FEATURE_SUBSCRIPTION {
	LIVE = 'LIVE',
	POST_REPLY = 'POST_REPLY',
	SYNC_CHAT = 'SYNC_CHAT',
	ORDER_CHANNEL = 'ORDER_CHANNEL',
}

export enum FEATURE_MONTH {
	ONE_MONTH = '1',
	THREE_MONTH = '3',
	SIX_MONTH = '6',
}

export type SelectFeature = {
	month: string | number
	feature: FEATURE_SUBSCRIPTION[]
}

export type GetSubscriptionPayload = {
	shopId: string
	select?: SelectFeature[]
}

export type SubscriptionRes = {
	month: string
	recommend: boolean
	features: {
		name: string
		price: number
	}[]
	totalPrice: number
	discount: number
}

export type GenerateQRPayload = {
	shopId: string
	month: string
	select: FEATURE_SUBSCRIPTION[]
}

export interface ResponseGenerateQR {
	paymentAmount: number
	transactionId: string
	tradeNo: string
	qrRawData: string
	qrExpireTime: string
}
