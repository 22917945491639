import { ref } from 'vue'
import { getSubscriptionList } from '@/api/subscription/subscription.api'
import { FEATURE_SUBSCRIPTION, SelectFeature } from '@/api/subscription/subscription.type'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import dayjs from '@/utils/dayjs'

export const useSubscription = () => {

	const shopStore = useShopStore()
	const isFetching = ref(false)
	const subscriptionList = ref()
	const oneMonthPrice = ref()
	const fetchListError = ref()

	const fetchSubscriptionList = async (select?: SelectFeature[]) => {
		try {
			isFetching.value = true
			const payload = {
				shopId: shopStore.selectedShopId as string,
				select,
			}
			const subList = await getSubscriptionList(payload)

			subscriptionList.value = subList.map((each) => ({
				...each,
				features: each.features.map((feature) => ({
					...feature,
					isSelected: true,
					isShowDetail: true,
				})),
			}))

			const getOneMonth = subscriptionList.value.find((data:any) => data.month === '1')
			oneMonthPrice.value = ((getOneMonth.totalPrice - getOneMonth.discount) / getOneMonth.month)
		} catch (error) {
			fetchListError.value = error
		} finally {
			isFetching.value = false
		}
	}

	const displayName = (name: string) => {
		if (FEATURE_SUBSCRIPTION.LIVE === name) {
			return 'ถ่ายทอดสด'
		}
		if (FEATURE_SUBSCRIPTION.POST_REPLY === name) {
			return 'ตอบกลับเมนต์อัตโนมัติ'
		}
		if (FEATURE_SUBSCRIPTION.SYNC_CHAT === name) {
			return 'แชท'
		}
		if (FEATURE_SUBSCRIPTION.ORDER_CHANNEL === name) {
			return 'ทุกฟีเจอร์'
		}
		return '-'
	}

	const expiredDisplayDate = (date: number) => {
		
		const todayNow = dayjs().unix()
		const now = dayjs()
		const expired = dayjs.unix(date)

		const diffDate = date - todayNow
		const expiredDate = expired.diff(now, 'd')
		if (diffDate <= 86400 && diffDate > 0) {
			return 'น้อยกว่า 1 '
		}
		if (diffDate <= 0) {
			return 'expired'
		}

		return expiredDate
	}

	const displayForNow = (month: string, expired: number) => {
		const timeNow = dayjs()
		if( expired === null || expired < timeNow.unix()){
			// ยังไม่หมดอายุ && null
			const expireDate = dayjs.unix(dayjs().unix()).add(Number(month), 'month')
			return expireDate.format('DD/MM/YYYY')
		}else{
			// หมดอายุแล้ว
			const expireDate = dayjs.unix(expired).add(Number(month), 'month')
			return expireDate.format('DD/MM/YYYY')
		}
	}


	return {
		oneMonthPrice,
		isFetching,
		subscriptionList,
		fetchSubscriptionList,
		displayName,
		expiredDisplayDate,
		displayForNow,
	}
}